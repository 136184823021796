import { judgeHbToken } from '../aggApi/hbApi/util';
import * as qs from 'qs'
import VConsole from 'vconsole';
import { isApp } from './utils';

export default function getVconsole(){
    const hbToken = judgeHbToken()
    const query = qs.parse(window.location.search,{ignoreQueryPrefix:true})
    // 测试环境和预发环境   hb页面和app  加入vconsole
    if (process.env.NODE_ENV === 'development') {
        if(hbToken || isApp){

            // if(hbToken || isApp){
            const vConsole = new VConsole();
        }
    }
    // }

}

